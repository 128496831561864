import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createI18n } from 'vue-i18n'
import mitt from 'mitt';

import translations from "./resources/translations";
import "@/assets/scss/app.scss";

const i18n = createI18n({
    locale: "de",
    fallbackLocale: "de",
    messages: translations
});

const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(i18n);
app.use(store);
app.use(router);

app.mount("#app");
