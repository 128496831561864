import { createRouter, createWebHistory } from "vue-router";
//import Home from "../views/Home.vue";

import Layout from "@/views/layout/index";

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "/",
        component: () => import(/* webpackChunkName: "home" */ "@/views/home/index"),
        name: "home",
        meta: {
          title: "Kuenz Naturbrennerei",
          noCache: true
        }
      }
    ]
  },
  {
    path: "/about",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "/about",
        component: () => import(/* webpackChunkName: "about" */ "@/views/about/index"),
        name: "about",
        meta: {
          title: "Über Kuenz Naturbrennerei",
          noCache: true
        }
      }
    ]
  },
  {
    path: "/download/:hash",
    component: Layout,
    redirect: "index",
    hidden: true,
    alwaysShow: true,
    children: [
      {
        path: "/download/:hash",
        component: () => import(/* webpackChunkName: "download" */ "@/views/download/index"),
        name: "download",
        meta: {
          title: "Foto download",
          noCache: true
        }
      }
    ]
  },

  /* {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ /*"../views/About.vue"),
  }, */
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
