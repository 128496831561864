export default {
    de: {
        headlines: {
            welcome: 'Herzlich Willkommen',
            sent_link_success: "Email versendet",
            gdpr: "Datenschutzerklärung"
        },
        messages: {
            enter_your_data: 'Bitte gib hier den Code von deinem Foto und deine Daten ein, dann kannst du dein Foto herunterladen.',
            sent_link: "Wir haben dir einen Link auf deine Email Adresse geschickt. Mit diesem Link kannst du das gewünschte Foto herunterladen.",
            enter_your_code: "Bitte gib hier noch einmal deinen Foto Download Code ein.",
            download_original: "Hier oder auf das Bild klicken um das Originalbild zu öffnen"
        },
        fields: {
            label: {
                foto_code: "Foto Code",
                first_name: "Vorname",
                last_name: "Nachname",
                emailAddress: "email",
                gdpr: "Ich bin mit der Vearbeitung meiner Daten lt. Datenschutzrichtlinie einverstanden"
            },
            placeholder: {
                foto_code: "Den Foto Code des Bons eingeben",
                first_name: "Vorname eingeben",
                last_name: "Nachname eingeben",
                emailAddress: "Email Adresse eingeben"
            },
        },
        labels: {
            email_address: "Email Adresse"
        },
        buttons: {
            download: "Download",
            guidelogin: "Guide",
            guidelogout: "Abmelden",
            close: "schließen",
            back: "zurück",
            customerlogout: "Abmelden",
            show_gdpr: "Datenschutzrichtlinie anzeigen"
        }
    },
    en: {
        headlines: {
            welcome: "Welcome"
        },
        messages: {
            enter_your_data: 'please enter your image code and your data to download your image',
            enter_your_code: "please enter once again your downloadcode."
        },
        fields: {
            label: {
                foto_code: "image code",
                first_name: "firstname",
                last_name: "lastname",
                emailAddress: "email"
            },
            placeholder: {
                foto_code: "enter the code on your image coupon",
                first_name: "enter your firstname",
                last_name: "enter your lastname",
                emailAddress: "enter your email"
            }
        },
        buttons: {
            download: "download",
            guidelogout: "Logout",
            close: "close",
            back: "back"
        }
    },
    it: {    
        headlines: {
            welcome: "Benvenutti"
        },
        messages: {
            enter_your_data: 'please enter your image code and your data to download your image'
        },
        fields: {
            label: {
                foto_code: "code imagine",
                first_name: "nome",
                last_name: "cognome",
                emailAddress: "Email"
            },
            placeholder: {
                foto_code: "enter the code on your image coupon",
                first_name: "accedere nome",
                last_name: "accedere cognome",
                emailAddress: "accedere email"
            }
        },
        buttons: {
            download: "download",
            guidelogin: "Guide",
            guidelogout: "logout",
            close: "chuida",
            back: "indietro"
        }
    }
};
  