<template>
  <div class="app-layout">
      <div id="nav" v-if="false">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div>
    <main class="appmain" >
      <router-view :key="key" />
    </main>      
  </div>
</template>

<script>

export default {
  name: "Layout",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
};
</script>
